<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/1.jpg" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/2.jpg" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/3.jpg" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/4.jpg" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/5.jpg" alt="image">
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="partner-item">
                <img src="assets/img/partner/6.jpg" alt="image">
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
