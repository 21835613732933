<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Via%20Ada%20Negri,%2015,%2088900%20Crotone%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Chi siamo</span>
                    <h2>Istituto Universitario<br>di Studi Europei
                    </h2>
                   <p>
                    fondato a Crotone nel 1990, e' stato successivamente assorbito nel 1994 dal "Gruppo Rocca Srl", Società di Management di Marketing e Servizi alle Imprese.
                   </p>
                   <p>
                    L'Istituto si propone come scuola a livello Post-Universitario specializzata in discipline economiche, Finanziarie della Comunicazione e delle organizzazioni delle risorse nonchè per la formazione di quadri aziendali, per la qualificazione e riqualificazione professionale.
                   </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">dalla formazione alla professione</span>
                    <h2>Finalità e Attività dell'Istituto</h2>
                    <p>
                        L'Istituto Universitario di Studi Europei, per le proprie peculiarità strutturali, per i mezzi tecnici di cui e' dotato e per il qualificatissimo apporto scientifico di cui si avvale, e' inoltre perfettamente in grado di gestire Corsi di Formazione, rivolti al personale dipendente di Enti e Pubbliche  Istituzioni, Aziende di qualsiasi dimensioni, Istituti Finanziari e di Credito.
                    </p>
                    <p>
                        L'attività di ricerca e formazione dell'Istituto è prioritariamente ispirata a temi inerenti le dinamiche sociali, la Politica Economica e la Finanza dell'Unione Europea. Per tale propensione, L'Istituto organizza dei Masters di specializzazione post-Universitari per i cittadini italiani e dell'U.E. che volessero acquisire le conoscenze e gli strumenti per operare, ciascuno nel proprio settore, in ambito continentale e intercontinentale.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <h6 class="mb-0">Sede Amministrativa</h6>
                            <p>Via Firenze, 14 - 88900 Crotone KR</p>
                            <h6 class="mb-0 mt-3">Sede Operativa</h6>
                            <p>Via Ada Negri, 15 - 88900 Crotone KR</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+390962964868">(+39) 0962-964868</a></p>
                            <p>e-mail: <a href="mailto:info@iusekr.it">info@iusekr.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì:<br>
                                09:00÷13:00 / 15:30÷19:30</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
